<script>
import "@vueform/multiselect/themes/default.css";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../app.config";

import animationData from "@/components/widgets/kbtmbyzy.json";

export default {
  page: {
    title: "RFQ Details",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "RFQ Details",
      table_options: {
        current_page: 1,
        page_size: 10,
        search_text: "",
        rfqs_id: this.$route.params.id,
        sort: { column: "", direction: "" },
      },
      meta: {},
      rfqResponseList: [],
      rfqDocs: [],
      items: [
        {
          text: "RFQ",
          href: "/",
        },
        {
          text: "RFQ Details",
          active: true,
        },
      ],
      value: null,
      defaultOptions: { animationData: animationData },
    };
  },
  components: {
    Layout,
    PageHeader,
  },

  mounted() {
    this.getSingleRFQ();
    this.getRFQsResponse();
    this.getRFQsDocs();
  },

  methods: {
    getSingleRFQ() {
      this.$store
        .dispatch("rfqs/getSingleRFQ", this.$route.params.id)
        .then((response) => console.log(response));
    },
    getRFQsResponse() {
      this.$store
        .dispatch("rfqs/getRFQsResponse", this.table_options)
        .then((res) => {
          //   console.log(res);
          this.rfqResponseList = res.data;
          this.meta.total = res.total;
          this.meta.from = res.from;
          this.meta.last_page = res.last_page;
        });
    },
    getRFQsDocs() {
      this.$store
        .dispatch("rfqs/getRFQsDocs", this.table_options)
        .then((res) => {
          console.log(res);
          this.rfqDocs = res.data;
          this.meta.total = res.total;
          this.meta.from = res.from;
          this.meta.last_page = res.last_page;
        });
    },
    handlePageChange(e) {
      this.table_options.current_page = e;
      this.$store
        .dispatch("rfqs/getRFQsResponse", this.table_options)
        .then((response) => {
          this.rfqResponseList = response.data;
          this.meta.total = response.total;
          this.meta.from = response.from;
          this.meta.last_page = response.last_page;
        });
    },
    handleFileChange(e) {
      this.table_options.current_page = e;
      this.$store
        .dispatch("rfqs/getRFQsResponse", this.table_options)
        .then((response) => {
          this.rfqDocs = response.data;
          this.meta.total = response.total;
          this.meta.from = response.from;
          this.meta.last_page = response.last_page;
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xxl-3">
        <!--end card-->
        <div class="card mb-3">
          <div class="card-body">
            <div class="table-card">
              <table class="table mb-0">
                <tbody>
                  <tr>
                    <td class="fw-medium">Code</td>
                    <td>#VLZ456</td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Phone</td>
                    <td>Profile Page Satructure</td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Email</td>
                    <td>Velzon - Admin Dashboard</td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Country</td>
                    <td><span class="badge badge-soft-danger">High</span></td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Customer</td>
                    <td>
                      <span class="badge badge-soft-secondary">Inprogress</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Due Date</td>
                    <td>05 Jan, 2022</td>
                  </tr>
                </tbody>
              </table>
              <!--end table-->
            </div>
          </div>
        </div>
        <!--end card-->
        <div class="card mb-3">
          <div class="card-body">
            <div class="d-flex mb-3">
              <h6 class="card-title mb-0 flex-grow-1">Assigned To</h6>
              <div class="flex-shrink-0">
                <button
                  type="button"
                  class="btn btn-soft-danger btn-sm shadow-none"
                  data-bs-toggle="modal"
                  data-bs-target="#inviteMembersModal"
                >
                  <i class="ri-share-line me-1 align-bottom"></i> Assigned
                  Member
                </button>
              </div>
            </div>
            <ul class="list-unstyled vstack gap-3 mb-0">
              <li>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <img
                      src="@/assets/images/users/avatar-10.jpg"
                      alt=""
                      class="avatar-xs rounded-circle shadow"
                    />
                  </div>
                  <div class="flex-grow-1 ms-2">
                    <h6 class="mb-1">
                      <router-link to="/pages/profile" class="text-body"
                        >Tonya Noble</router-link
                      >
                    </h6>
                    <p class="text-muted mb-0">Full Stack Developer</p>
                  </div>
                  <div class="flex-shrink-0">
                    <div class="dropdown">
                      <button
                        class="btn btn-icon btn-sm fs-16 text-muted dropdown shadow-none"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="ri-more-fill"></i>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i
                              class="ri-eye-fill text-muted me-2 align-bottom"
                            ></i
                            >View</a
                          >
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i
                              class="ri-star-fill text-muted me-2 align-bottom"
                            ></i
                            >Favourite</a
                          >
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i
                              class="ri-delete-bin-5-fill text-muted me-2 align-bottom"
                            ></i
                            >Delete</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <img
                      src="@/assets/images/users/avatar-8.jpg"
                      alt=""
                      class="avatar-xs rounded-circle shadow"
                    />
                  </div>
                  <div class="flex-grow-1 ms-2">
                    <h6 class="mb-1">
                      <router-link to="/pages/profile" class="text-body"
                        >Thomas Taylor</router-link
                      >
                    </h6>
                    <p class="text-muted mb-0">UI/UX Designer</p>
                  </div>
                  <div class="flex-shrink-0">
                    <div class="dropdown">
                      <button
                        class="btn btn-icon btn-sm fs-16 text-muted dropdown shadow-none"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="ri-more-fill"></i>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i
                              class="ri-eye-fill text-muted me-2 align-bottom"
                            ></i
                            >View</a
                          >
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i
                              class="ri-star-fill text-muted me-2 align-bottom"
                            ></i
                            >Favourite</a
                          >
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i
                              class="ri-delete-bin-5-fill text-muted me-2 align-bottom"
                            ></i
                            >Delete</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <img
                      src="@/assets/images/users/avatar-2.jpg"
                      alt=""
                      class="avatar-xs rounded-circle shadow"
                    />
                  </div>
                  <div class="flex-grow-1 ms-2">
                    <h6 class="mb-1">
                      <router-link to="/pages/profile" class="text-body"
                        >Nancy Martino</router-link
                      >
                    </h6>
                    <p class="text-muted mb-0">Web Designer</p>
                  </div>
                  <div class="flex-shrink-0">
                    <div class="dropdown">
                      <button
                        class="btn btn-icon btn-sm fs-16 text-muted dropdown shadow-none"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="ri-more-fill"></i>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i
                              class="ri-eye-fill text-muted me-2 align-bottom"
                            ></i
                            >View</a
                          >
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i
                              class="ri-star-fill text-muted me-2 align-bottom"
                            ></i
                            >Favourite</a
                          >
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:void(0);"
                            ><i
                              class="ri-delete-bin-5-fill text-muted me-2 align-bottom"
                            ></i
                            >Delete</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!---end col-->
      <div class="col-xxl-9">
        <div class="card">
          <div class="card-body">
            <div class="text-muted">
              <h6 class="mb-3 fw-semibold text-uppercase">Summary</h6>
              <p>
                It will be as simple as occidental in fact, it will be
                Occidental. To an English person, it will seem like simplified
                English, as a skeptical Cambridge friend of mine told me what
                Occidental is. The European languages are members of the same
                family. Their separate existence is a myth. For science, music,
                sport, etc, Europe uses the same vocabulary. The languages only
                differ in their grammar, their pronunciation and their most
                common words.
              </p>

              <h6 class="mb-3 fw-semibold text-uppercase">Sub-tasks</h6>
              <ul class="ps-3 list-unstyled vstack gap-2">
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="productTask"
                    />
                    <label class="form-check-label" for="productTask">
                      Product Design, Figma (Software), Prototype
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="dashboardTask"
                      checked
                    />
                    <label class="form-check-label" for="dashboardTask">
                      Dashboards : Ecommerce, Analytics, Project,etc.
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="calenderTask"
                    />
                    <label class="form-check-label" for="calenderTask">
                      Create calendar, chat and email app pages
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="authenticationTask"
                    />
                    <label class="form-check-label" for="authenticationTask">
                      Add authentication pages
                    </label>
                  </div>
                </li>
              </ul>

              <div class="pt-3 border-top border-top-dashed mt-4">
                <h6 class="mb-3 fw-semibold text-uppercase">Tasks Tags</h6>
                <div class="hstack flex-wrap gap-2 fs-15">
                  <div class="badge fw-medium badge-soft-info">UI/UX</div>
                  <div class="badge fw-medium badge-soft-info">Dashboard</div>
                  <div class="badge fw-medium badge-soft-info">Design</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end card-->
        <div class="card">
          <div class="card-header">
            <div>
              <ul
                class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    data-bs-toggle="tab"
                    href="#home-1"
                    role="tab"
                  >
                    Notes (5)
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-bs-toggle="tab"
                    href="#messages-1"
                    role="tab"
                  >
                    Attachments File ({{rfqDocs.length}})
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-bs-toggle="tab"
                    href="#profile-1"
                    role="tab"
                  >
                    RFQ Responses
                  </a>
                </li>
              </ul>
              <!--end nav-->
            </div>
          </div>
          <div class="card-body">
            <div class="tab-content">
              <div class="tab-pane active" id="home-1" role="tabpanel">
                <h5 class="card-title mb-4">Notes</h5>
                <div
                  data-simplebar
                  style="height: 508px"
                  class="px-3 mx-n3 mb-2"
                >
                  <div class="d-flex mb-4">
                    <div class="flex-shrink-0">
                      <img
                        src="@/assets/images/users/avatar-7.jpg"
                        alt=""
                        class="avatar-xs rounded-circle shadow"
                      />
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h5 class="fs-13">
                        <router-link to="/pages/profile" class="text-body"
                          >Joseph Parker</router-link
                        >
                        <small class="text-muted">20 Dec 2021 - 05:47AM</small>
                      </h5>
                      <p class="text-muted">
                        I am getting message from customers that when they place
                        order always get error message .
                      </p>
                      <a
                        href="javascript: void(0);"
                        class="badge text-muted bg-light"
                        ><i class="mdi mdi-reply"></i> Reply</a
                      >
                      <div class="d-flex mt-4">
                        <div class="flex-shrink-0">
                          <img
                            src="@/assets/images/users/avatar-10.jpg"
                            alt=""
                            class="avatar-xs rounded-circle shadow"
                          />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h5 class="fs-13">
                            <router-link to="/pages/profile" class="text-body"
                              >Tonya Noble</router-link
                            >
                            <small class="text-muted"
                              >22 Dec 2021 - 02:32PM</small
                            >
                          </h5>
                          <p class="text-muted">
                            Please be sure to check your Spam mailbox to see if
                            your email filters have identified the email from
                            Dell as spam.
                          </p>
                          <a
                            href="javascript: void(0);"
                            class="badge text-muted bg-light"
                            ><i class="mdi mdi-reply"></i> Reply</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                
                </div>
                <form class="mt-2">
                  <div class="row g-3">
                    <div class="col-lg-12">
                      <label
                        for="exampleFormControlTextarea1"
                        class="form-label"
                        >Leave a Comments</label
                      >
                      <textarea
                        class="form-control bg-light border-light"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Enter comments"
                      ></textarea>
                    </div>
                    <!--end col-->
                    <div class="col-12 text-end">
                      <button
                        type="button"
                        class="btn btn-ghost-secondary btn-icon waves-effect me-1"
                      >
                        <i class="ri-attachment-line fs-16"></i>
                      </button>
                      <a href="javascript:void(0);" class="btn btn-success"
                        >Post Comments</a
                      >
                    </div>
                  </div>
                  <!--end row-->
                </form>
              </div>
              <!--end tab-pane-->
              <div class="tab-pane" id="messages-1" role="tabpanel">
                <div class="table-responsive table-card">
                  <table class="table table-borderless align-middle mb-0">
                    <thead class="table-light text-muted">
                      <tr>
                        <th scope="col">File Name</th>
                        <th scope="col">Document</th>
                        <th scope="col">RFQ ID</th>
                        <th scope="col">Updated Date</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) of rfqDocs" :key="index">
                        <td>
                          <div class="d-flex align-items-center">
                            <div class="avatar-sm">
                              <div
                                class="avatar-title bg-soft-primary text-primary rounded fs-20"
                              >
                                <i class="ri-file-zip-fill"></i>
                              </div>
                            </div>
                            <div class="ms-3 flex-grow-1">
                              <h6 v-if="item.title" class="fs-15 mb-0">
                                {{
                                  item.title.length > 10
                                    ? item.title.slice(0, 10)
                                    : item.title
                                }}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>{{ item.document }}</td>
                        <td>{{ item.rfq_id }}</td>
                        <td>{{ item.updated_at }}</td>
                        <td>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0);"
                              class="btn btn-light btn-icon"
                              id="dropdownMenuLink1"
                              data-bs-toggle="dropdown"
                              aria-expanded="true"
                            >
                              <i class="ri-equalizer-fill"></i>
                            </a>
                            <ul
                              class="dropdown-menu dropdown-menu-end"
                              aria-labelledby="dropdownMenuLink1"
                              data-popper-placement="bottom-end"
                              style="
                                position: absolute;
                                inset: 0px 0px auto auto;
                                margin: 0px;
                                transform: translate(0px, 23px);
                              "
                            >
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="javascript:void(0);"
                                  ><i
                                    class="ri-eye-fill me-2 align-middle text-muted"
                                  ></i
                                  >View</a
                                >
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="javascript:void(0);"
                                  ><i
                                    class="ri-download-2-fill me-2 align-middle text-muted"
                                  ></i
                                  >Download</a
                                >
                              </li>
                              <li class="dropdown-divider"></li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="javascript:void(0);"
                                  ><i
                                    class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                  ></i
                                  >Delete</a
                                >
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    @click="handleFileChange(table_options.current_page)"
                    class="d-flex mt-4 mx-3 align-items-center justify-content-end"
                  >
                    <b-pagination
                      v-model="table_options.current_page"
                      :total-rows="meta.total"
                      :per-page="table_options.page_size"
                      prev-text="Prev"
                      next-text="Next"
                      prev-class="prev"
                      next-class="next"
                      class="pagination-wrap hstack gap-2"
                    >
                      <template #prev-text>
                        <div
                          class="page-item shadow-sm pagination-prev disabled"
                        >
                          Previous
                        </div>
                      </template>
                      <template #next-text>
                        <div class="page-item shadow-sm pagination-next">
                          Next
                        </div>
                      </template>
                    </b-pagination>
                  </div>
                  <!--end table-->
                </div>
              </div>
              <!--end tab-pane-->
              <div class="tab-pane" id="profile-1" role="tabpanel">
                <h6 class="card-title mb-4 pb-2">Responses</h6>
                <div class="table-responsive table-card">
                  <table class="table align-middle mb-0">
                    <thead class="table-light text-muted">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Date</th>
                        <th scope="col">Contact</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) of rfqResponseList" :key="index">
                        <th scope="row">
                          {{ item.rfq_response_attention }}
                        </th>
                        <td>{{ item.rfq_response_date }}</td>
                        <td>{{ item.rfq_sp_contact }}</td>
                        <td>{{ item.rfq_sp_phone }}</td>
                        <td>{{ item.rfq_sp_email }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    @click="handlePageChange(table_options.current_page)"
                    class="d-flex mt-4 mx-3 align-items-center justify-content-end"
                  >
                    <b-pagination
                      v-model="table_options.current_page"
                      :total-rows="meta.total"
                      :per-page="table_options.page_size"
                      prev-text="Prev"
                      next-text="Next"
                      prev-class="prev"
                      next-class="next"
                      class="pagination-wrap hstack gap-2"
                    >
                      <template #prev-text>
                        <div
                          class="page-item shadow-sm pagination-prev disabled"
                        >
                          Previous
                        </div>
                      </template>
                      <template #next-text>
                        <div class="page-item shadow-sm pagination-next">
                          Next
                        </div>
                      </template>
                    </b-pagination>
                  </div>
                  <!--end table-->
                </div>
              </div>
              <!--edn tab-pane-->
            </div>
            <!--end tab-content-->
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div
      class="modal fade"
      id="inviteMembersModal"
      tabindex="-1"
      aria-labelledby="inviteMembersModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0">
          <div class="modal-header p-3 ps-4 bg-soft-success">
            <h5 class="modal-title" id="inviteMembersModalLabel">
              Team Members
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-4">
            <div class="search-box mb-3">
              <input
                type="text"
                class="form-control bg-light border-light"
                placeholder="Search here..."
              />
              <i class="ri-search-line search-icon"></i>
            </div>

            <div class="mb-4 d-flex align-items-center">
              <div class="me-2">
                <h5 class="mb-0 fs-13">Members :</h5>
              </div>
              <div class="avatar-group justify-content-center">
                <a
                  href="javascript: void(0);"
                  class="avatar-group-item"
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="Tonya Noble"
                >
                  <div class="avatar-xs">
                    <img
                      src="@/assets/images/users/avatar-10.jpg"
                      alt=""
                      class="rounded-circle img-fluid"
                    />
                  </div>
                </a>
                <a
                  href="javascript: void(0);"
                  class="avatar-group-item"
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="Thomas Taylor"
                >
                  <div class="avatar-xs">
                    <img
                      src="@/assets/images/users/avatar-8.jpg"
                      alt=""
                      class="rounded-circle img-fluid"
                    />
                  </div>
                </a>
                <a
                  href="javascript: void(0);"
                  class="avatar-group-item"
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="Nancy Martino"
                >
                  <div class="avatar-xs">
                    <img
                      src="@/assets/images/users/avatar-2.jpg"
                      alt=""
                      class="rounded-circle img-fluid"
                    />
                  </div>
                </a>
              </div>
            </div>
            <div class="mx-n4 px-4" data-simplebar style="max-height: 225px">
              <div class="vstack gap-3">
                <div class="d-flex align-items-center">
                  <div class="avatar-xs flex-shrink-0 me-3">
                    <img
                      src="@/assets/images/users/avatar-2.jpg"
                      alt=""
                      class="img-fluid rounded-circle"
                    />
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="fs-13 mb-0">
                      <a href="javascript:void(0);" class="text-body d-block"
                        >Nancy Martino</a
                      >
                    </h5>
                  </div>
                  <div class="flex-shrink-0">
                    <button type="button" class="btn btn-light btn-sm">
                      Add
                    </button>
                  </div>
                </div>
                <!-- end member item -->
                <div class="d-flex align-items-center">
                  <div class="avatar-xs flex-shrink-0 me-3">
                    <div
                      class="avatar-title bg-soft-danger text-danger rounded-circle"
                    >
                      HB
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="fs-13 mb-0">
                      <a href="javascript:void(0);" class="text-body d-block"
                        >Henry Baird</a
                      >
                    </h5>
                  </div>
                  <div class="flex-shrink-0">
                    <button type="button" class="btn btn-light btn-sm">
                      Add
                    </button>
                  </div>
                </div>
                <!-- end member item -->
                <div class="d-flex align-items-center">
                  <div class="avatar-xs flex-shrink-0 me-3">
                    <img
                      src="@/assets/images/users/avatar-3.jpg"
                      alt=""
                      class="img-fluid rounded-circle"
                    />
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="fs-13 mb-0">
                      <a href="javascript:void(0);" class="text-body d-block"
                        >Frank Hook</a
                      >
                    </h5>
                  </div>
                  <div class="flex-shrink-0">
                    <button type="button" class="btn btn-light btn-sm">
                      Add
                    </button>
                  </div>
                </div>
                <!-- end member item -->
                <div class="d-flex align-items-center">
                  <div class="avatar-xs flex-shrink-0 me-3">
                    <img
                      src="@/assets/images/users/avatar-4.jpg"
                      alt=""
                      class="img-fluid rounded-circle"
                    />
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="fs-13 mb-0">
                      <a href="javascript:void(0);" class="text-body d-block"
                        >Jennifer Carter</a
                      >
                    </h5>
                  </div>
                  <div class="flex-shrink-0">
                    <button type="button" class="btn btn-light btn-sm">
                      Add
                    </button>
                  </div>
                </div>
                <!-- end member item -->
                <div class="d-flex align-items-center">
                  <div class="avatar-xs flex-shrink-0 me-3">
                    <div
                      class="avatar-title bg-soft-success text-success rounded-circle"
                    >
                      AC
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="fs-13 mb-0">
                      <a href="javascript:void(0);" class="text-body d-block"
                        >Alexis Clarke</a
                      >
                    </h5>
                  </div>
                  <div class="flex-shrink-0">
                    <button type="button" class="btn btn-light btn-sm">
                      Add
                    </button>
                  </div>
                </div>
                <!-- end member item -->
                <div class="d-flex align-items-center">
                  <div class="avatar-xs flex-shrink-0 me-3">
                    <img
                      src="@/assets/images/users/avatar-7.jpg"
                      alt=""
                      class="img-fluid rounded-circle"
                    />
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="fs-13 mb-0">
                      <a href="javascript:void(0);" class="text-body d-block"
                        >Joseph Parker</a
                      >
                    </h5>
                  </div>
                  <div class="flex-shrink-0">
                    <button type="button" class="btn btn-light btn-sm">
                      Add
                    </button>
                  </div>
                </div>
                <!-- end member item -->
              </div>
              <!-- end list -->
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light w-xs"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-success w-xs">Assigned</button>
          </div>
        </div>
        <!-- end modal-content -->
      </div>
      <!-- modal-dialog -->
    </div>
    <!-- end modal -->
  </Layout>
</template>
